/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Divider, Grid, Link, Toolbar } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontSize: 12,
    fontFamily: 'Poppins-Semibold',
    lineHeight: 1,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    '&:hover': {
      textDecoration: 'none',
      cursor: 'pointer',
      color: theme.palette.gray.dark,
    },
  },
  button: {
    marginLeft: '40px',
    paddingLeft: '30px',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  background: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    boxShadow: 'none',
  },
}));

const TopAppBar = () => {
  const classes = useStyles();

  return (
    <AppBar
      position="fixed"
      variant="dense"
      className={classes.background}
    >
      <Toolbar>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >

          <Grid item xs={4} sm={2}>
            <Link href="/">
              <img src="/static/images/logo.svg" width={60} height={30} alt="Cussplus logo" />
            </Link>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={8}
            sm={10}
          >
            <Link href="https://www.objective.se/" target="_blank" key="objective" variant="body1" className={classes.link}>
              objective.se
            </Link>
            <Divider orientation="vertical" variant="middle" flexItem style={{ backgroundColor: 'white' }} />
            <Link href="https://www.objective.se/contact/" target="_blank" key="objective_contact" variant="body1" className={classes.link}>
              + Get in touch
            </Link>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default TopAppBar;
