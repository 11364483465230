/* eslint-disable jsx-a11y/media-has-caption */
import { React } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '27px',
    backgroundColor: 'red',
    padding: 20,
    paddingTop: 30,
    alignItems: 'center',
    minHeight: 325,
  },
  title: {
    color: theme.palette.secondary.main,
    paddingTop: 15,
    paddingBottom: 15,
  },
  description: {
    color: theme.palette.secondary.main,
  },
}));

const ReasonCard = (props) => {
  const classes = useStyles();
  const {
    logo, title, description, background,
  } = props;

  return (
    <>
      {background ? (
        <Box className={classes.container} style={{ backgroundImage: `url('/static/images/${background}')`, backgroundSize: 'cover' }}>
          <Box className={classes.image}>
            <img src={`/static/images/card/${logo}.svg`} className={classes.imageTag} alt="Cargo logos" height="40" width="40" />
          </Box>
          <Typography variant="h6" className={classes.title}>{title}</Typography>
          <Typography variant="body2" className={classes.description}>{description}</Typography>
        </Box>
      ) : null}
    </>
  );
};

export default ReasonCard;
