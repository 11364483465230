/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import { React } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@material-ui/core';
import ReasonCard from './ReasonCard/ReasonCard';
// Styles
const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.main,
  },
}));

const FourReasons = () => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Box>
          <Typography variant="h3" className={classes.title}>4 Reasons to choose CUSS+&trade;</Typography>
        </Box>
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <ReasonCard
          logo="customizable"
          title="Customizable"
          description="Fully customizable, you decide the functionality, we’re not weighed down by old customers who want to keep everything the way it’s always been."
          background="heroImage.webp"
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <ReasonCard
          logo="fast"
          title="Fast"
          description="Our revolutionary platform operates 10 times faster than legacy platforms, a fact verified through rigorous benchmarking across 32 different airlines."
          background="heroImage.webp"
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <ReasonCard
          logo="tech"
          title="New Technology"
          description="Modern architecture: built with new technology, no legacy."
          background="heroImage.webp"
        />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <ReasonCard
          logo="support"
          title="Support"
          description="Our cutting-edge solution proudly supports the entire spectrum of CUSS versions, ranging from 1.0 to 2.2."
          background="heroImage.webp"
        />
      </Grid>
    </Grid>
  );
};

export default FourReasons;
