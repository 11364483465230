import React from 'react';
import Layout from '../src/components/Layout/Layout';
import Hero from '../src/components/Home/Hero/Hero';
import WhatIsCuss from '../src/components/Home/WhatIsCuss/WhatIsCuss';
import FourReasons from '../src/components/Home/FourReasons/FourReasons';
import Airlines from '../src/components/Home/Airlines/Airlines';

const Home = () => (
  <Layout hero={<Hero />}>
    <Airlines />
    <WhatIsCuss />
    <FourReasons />
  </Layout>
);

export default Home;
