import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link } from '@material-ui/core';
import { Box } from '@mui/material';
import moment from 'moment';

// Styles
const useStyles = makeStyles((theme) => ({
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: 'white',
    marginTop: 30,
    marginBottom: 35,
    [theme.breakpoints.down('sm')]: {
      marginTop: 80,
      marginBottom: 35,
    },
  },
  logo: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  linkText: {
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.gray.dark,
    },
  },
  container: {
    marginTop: 150,
    backgroundColor: theme.palette.primary.main,
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  rightText: {
    textAlign: 'end',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },

  copyright: {
    textAlign: 'center',
  },
  socialMedia: {
    marginLeft: 10,
  },
  image: {
    maxHeight: '80px',
  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box p={{ xs: 4, sm: 4, md: 12 }} pb={{ xs: 4, sm: 4, md: 4 }}>
        <Grid container spacing={8} justifyContent="center">
          <Grid item xs={12} sm={12} lg={3}>
            <Box className={classes.image}>
              <img src="/static/images/objectiveLogo.svg" className={classes.imageTag} alt="Objective logo" height="auto" width="200px" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Box className={classes.image} style={{ textAlign: 'center' }}>
              <img src="/static/images/cuss-plus-logo.svg" className={classes.imageTag} alt="Cussplus logo" height="auto" width="200px" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Box className={classes.image} style={{ textAlign: 'center' }}>
              <img src="/static/images/IATA.png" className={classes.imageTag} alt="Cussplus logo" height="auto" width="200px" />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} lg={3}>
            <Grid container spacing={1} justifyContent="center" className={classes.rightText}>
              <Grid item xs={12} sm={12} lg={12}>
                <Typography variant="body1" color="secondary" style={{ fontWeight: 'bold' }}>
                  Objective Solutions
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Link href="https://goo.gl/maps/XPTFqGwQNS9NeMF7A" target="_blank">
                  <Typography variant="body1" color="secondary" className={classes.linkText}>
                    Vasagatan 46 111 20 Stockholm Sweden
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Link href="mailto:hello@objective.se">
                  <Typography variant="body1" color="secondary" className={classes.linkText}>
                    hello@objective.se
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Link href="https://instagram.com/objective.solutions" target="_blank" className={classes.socialMedia}>
                  <img src="/static/images/socialMedia/instagram.svg" width={40} height={40} alt="Instagram logo" />
                </Link>
                <Link href="https://www.linkedin.com/company/objective-solutions-sweden-ab" target="_blank" className={classes.socialMedia}>
                  <img src="/static/images/socialMedia/linkedin.svg" width={40} height={40} alt="Linkedin logo" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box className={classes.divider} />
        <Box style={{ textAlign: 'center' }}>
          <Typography variant="body2" color="secondary" className={classes.copyright}>
            &copy; Objective Solutions Sweden AB
            {' '}
            {moment().format('YYYY')}
          </Typography>
          <Box mt={1} />
          <Typography variant="caption" color="secondary" className={classes.copyright}>
            All photos, media, and articles are copyrighted to their original owners.
          </Typography>
        </Box>
      </Box>

    </Box>
  );
};

export default Footer;
