/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import { React } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@material-ui/core';
import cargoImageList from '../../../utils/cargoImagesList';

// Styles
const useStyles = makeStyles((theme) => ({
  image: {
    position: 'relative',
    height: 530,
    zIndex: 101,
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  header: {
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    textAlign: 'left',
    paddingBottom: 20,
  },
  imageTag: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  descriptionPoints: {
    color: theme.palette.primary.main,
    fontSize: 12,
    paddingBottom: 10,
  },
  description: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

const WhatIsCuss = () => {
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" spacing={4}>
      <Grid item xs={12} md={7} order={{ xs: 2, md: 1 }}>
        <Box position="relative" zIndex="100">
          <Box animationIn="fadeIn" animationInDuration={1000}>
            <Typography variant="h3" className={classes.header}>What is CUSS+&trade;?</Typography>
            <Typography variant="body1" className={classes.description}>CUSS+&trade; is an innovative solution that is reshaping Common-Use-Self-Service applications, bringing a new dimension to the CUSS market.</Typography>
            <Box mt={2}>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>Comprehensive Version Support:</span>
                {' '}
                CUSS+&trade; is equipped to handle IATA CUSS versions 1.0,1.1,1.2,1.3,1.4,1.5,2.2, ensuring compatibility across the board.
              </Typography>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>Modern Architecture:</span>
                {' '}
                Built using the latest technology, CUSS+&trade; eradicates outdated issues and constraints.
              </Typography>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>Remarkable Speed:</span>
                {' '}
                10x faster than legacy platforms (according to our benchmarks with
                {' '}
                {cargoImageList?.length || '-'}
                {' '}
                airlines configured)
              </Typography>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>Intuitive User Interface:</span>
                {' '}
                Stand out from competitors with CUSS+&trade; user-friendly and intuitive interface.
              </Typography>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>“No touch” Innovation:</span>
                {' '}
                Scan any barcode without choosing an app, CUSS+&trade; chooses for you.
              </Typography>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>Hardware neutral:</span>
                {' '}
                CUSS+&trade; supports any kiosk, including special LEDs, sensors, etc. We have our own kiosk hardware if you need new kiosks, and will work with any hardware you would like to install, without the huge hardware costs of legacy platforms.
              </Typography>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>Tailored Customization:</span>
                {' '}
                Customize CUSS+&trade; according to your unique needs. We’re not weighed down by old customers who want to keep everything the way it’s always been.
              </Typography>
              <Typography variant="body1" className={classes.descriptionPoints}>
                <span style={{ fontWeight: 'bold' }}>Off-the-shelf CUWS solution:</span>
                {' '}
                CUSS+&trade; comes with full support to seamlessly run CUWS on a CUSS kiosk. Easily switch between CUSS and CUWS remotely, ensuring maximum airline compatibility. For airlines that don’t have a CUSS application they can use Objective’s CUWS application. Fully customisable for the targeted airport.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={5} order={{ xs: 1, md: 2 }}>
        <Box position="relative">
          <Box className={classes.image} animationIn="fadeIn" animationInDuration={1000}>
            <Box className={classes.image}>
              <img src="/static/images/obsol-kiosk.png" className={classes.imageTag} alt="Cuss" />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default WhatIsCuss;
