/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container } from '@material-ui/core';
import Footer from './Footer/Footer';
import AppBar from './AppBar/AppBar';
// Styles
const useStyles = makeStyles((theme) => ({
  body: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    margin: 0,
    padding: 0,
    maxWidth: '100%',
  },
}));

const Layout = (props) => {
  const classes = useStyles();
  const { children, hero } = props;
  return (
    <Box className={classes.body}>
      <AppBar hero={hero} />
      {hero}
      <Container maxWidth="lg">
        {children}
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;
