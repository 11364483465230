const cargoImageList = [
  'FI',
  'NAS',
  'SK',
  'LH',
  'AY',
  'AF',
  'BA',
  'BIX',
  'CAT',
  'CD_XC_XR',
  'CD',
  'charter',
  'DK',
  'DX',
  'FH',
  '8A',
  'TUI',
  'VING',
  'HV',
  'DETU',
  'LX',
  'FR',
  'FT',
  'GL',
  'JU',
  'OU',
  'GQ',
  'JTD',
  'KL',
  'A3',
  '08A',
  'NVR',
  'OS',
  'PC',
  'RC',
  '8H',
  'SN',
  'TK',
  'VY',
  'W4',
  'WF',
  'XC',
  'XQ',
  'XR',
  'TP',
  'APOL',
  'LANG',
  'NAZA',
  'FHM',
  'MLH',
  'BJ',
  'EW',
  'H6',
  'NE',
  'PE',
  'QS',
  'SM',
  'UJ',
  'YW',
];

export default cargoImageList;
