/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */
import { React, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import cargoImageList from '../../../utils/cargoImagesList';
import useMediaQuery from '../../../utils/mediaQuery';
// Styles
const useStyles = makeStyles((theme) => ({
  mainBox: {

    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-left',
    },
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 20,
    textAlign: 'center',
  },
}));

const Airlines = () => {
  const classes = useStyles();
  const [logoRowAmount, setLogoRowAmount] = useState(6);
  const amountToShow = 2;

  const isMobile = useMediaQuery(600);
  const isMiddle = useMediaQuery(900);

  useEffect(() => {
    if (isMobile) {
      setLogoRowAmount(4);
    } else if (isMiddle) {
      setLogoRowAmount(6);
    } else {
      setLogoRowAmount(6);
    }
  }, [isMobile, isMiddle]);

  let logoDataArray = cargoImageList.reduce((rows, key, index) => (index % logoRowAmount === 0 ? rows.push([key])
    : rows[rows.length - 1].push(key)) && rows, []);

  if (logoRowAmount === 6) {
    logoDataArray = logoDataArray.filter((item) => logoRowAmount === 6 && item.length >= amountToShow);
  }

  return (
    <Box position="relative" mt={4} mb={8}>
      <Box className={classes.mainBox}>
        <Box mb={5}>
          <Typography variant="h5" className={classes.title}>CERTIFIED AIRLINES</Typography>
        </Box>
        <Box>
          <Carousel
            showArrows={false}
            showThumbs={false}
            infiniteLoop
            emulateTouch
            interval={5000}
            transitionTime={1000}
            autoPlay
            showStatus={false}
            renderIndicator={false}
          >
            {
                logoDataArray.map((item, i) => (
                  <Grid container spacing={1} key={i}>
                    {
                      item.map((value, j) => (
                        <Grid item xs={6} sm={4} md={2} key={j}>
                          <img src={`/static/images/cargo/${value}.png`} className={classes.images} alt="Cargo logo" style={{ objectFit: 'contain', width: 100, height: 60 }} />
                        </Grid>
                      ))
                    }
                  </Grid>
                ))
            }
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

export default Airlines;
