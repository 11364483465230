/* eslint-disable jsx-a11y/media-has-caption */
import { React, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid } from '@material-ui/core';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import useMediaQuery from '../../../utils/mediaQuery';

// Styles
const useStyles = makeStyles((theme) => ({
  bg: {
    backgroundImage: `url(${'/static/images/heroImage.webp'})`,
    width: '101vw',
    height: '100vh',
    backgroundSize: 'cover',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  container: {
    paddingLeft: 100,
    height: 250,
    width: 900,
    [theme.breakpoints.between('0', '700')]: {
      height: 300,
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  titleText: {
    fontSize: 60,
    color: theme.palette.secondary.main,
    [theme.breakpoints.between('0', '700')]: {
      fontSize: 30,
    },
  },
  subTitleText: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.between('0', '700')]: {
      fontSize: 14,
    },
  },
  logo: {
    height: 'auto',
    width: 400,
    [theme.breakpoints.between('0', '700')]: {
      width: 200,
    },
  },
  arrow: {
    justifyContent: 'center',
    position: 'absolute',
    width: 50,
    height: 50,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bottom: 0,
    color: 'white',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  arrowPulseDown: {
    justifyContent: 'center',
    position: 'absolute',
    width: 50,
    height: 25,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bottom: 0,
    color: 'white',
    animation: '$arrowPulseDown 1300ms linear infinite',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  '@keyframes arrowPulseDown': {
    '0%': {
      bottom: 40,
      opacity: 0,
    },
    '25%': {
      opacity: 0.25,
    },
    '50%': {
      opacity: 0.5,
    },
    '75%': {
      opacity: 0.75,
    },
    '100%': {
      bottom: 20,
      opacity: 1,
    },
  },
}));

const Hero = () => {
  const classes = useStyles();
  const myRef = useRef(null);

  const isMobile = useMediaQuery(600);

  const handleScrollDown = () => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, myRef.current.offsetHeight - (isMobile ? 56 : 64));
    }
  };

  return (
    <Box className={classes.wrapper} ref={myRef} display="flex" flexDirection="column" alignItems="center" justifyContent="space-between" height="100vh">
      <Box className={classes.bg}>
        <Grid container justifyContent="center" className={classes.container}>
          <Grid item xs={12}>
            <Box mb={4}>
              <img src="/static/images/cuss-plus-logo.svg" className={classes.logo} alt="Cusspluss logo" />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.titleText}>
              Introducing the ultimate CUSS-platform solution.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1}>
              <Typography variant="body1" className={classes.subTitleText}>
                With Objective Solutions and CUSS+&trade;, traveler journeys become effortless.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.arrowPulseDown} onClick={() => handleScrollDown()}>
          <KeyboardDoubleArrowDownIcon size="large" style={{ fontSize: 50 }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
